/* Base css configuration for use across all applications aligned to the Design System */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Playfair+Display:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-grey-1;
  }

  html,
  body {
    @apply h-full;
  }
}

:root {
  --coral-dark: #f1662f;
  --coral-med: #ffaa95;
  --coral-light: #fadfcf;
  --navy-dark: #2a3663;
  --navy-med: #677fd0;
  --navy-light: #d0d7f3;
  --teal-dark: #098080;
  --teal-med: #83c1c1;
  --teal-light: #d3e8e8;
  --grey-1: #181a1b;
  --grey-2: #60686c;
  --grey-3: #959a9d;
  --grey-4: #cacdce;
  --grey-5: #e4e5e5;
  --grey-6: #f7f7f7;
  --error-dark: #de0707;
  --error-med: #eb7f7f;
  --error-light: #f9c6c6;
  --success-dark: #31983b;
  --success-med: #9fcba3;
  --success-light: #d7efda;
  --warning-dark: #f6c319;
  --warning-med: #f9e39b;
  --warning-light: #fff7dc;
  --white-pure: #ffffff;
}
