/* Comment to workaround CSS ordering bug (https://github.com/vercel/next.js/issues/16630#issuecomment-1037305875) */
@import '../../../libs/component-library/src/global-styles.css';
/* @import '@aws-amplify/ui-react/dist/styles.css'; */

/* Patch to remove style in amplify stylesheet */
body {
  min-height: auto;
}

#__next {
  @apply h-full;
}

:root {
  --amplify-colors-background-primary: var(--white-pure);
  --amplify-colors-background-secondary: var(--grey-6);
  --amplify-colors-font-primary: var(--grey-1);
  --amplify-colors-brand-primary-10: var(--teal-light);
  --amplify-colors-brand-primary-100: var(--teal-dark);
  --amplify-colors-brand-primary-90: var(--coral-med);
  --amplify-colors-brand-primary-80: var(--coral-dark);
  --amplify-colors-red-80: var(--error-light);
  --amplify-colors-red-90: var(--error-dark);
  --amplify-colors-red-100: var(--error-dark);
  --amplify-colors-border-error: var(--error-dark);
}

.global-auth-wrapper {
  background-color: var(--white-pure) !important;
}

.global-auth-wrapper:before {
  content: '';
  background-color: var(--white-pure) !important;
  background: url('../public/images/logo.png') no-repeat center;
  background-size: 200px;
  background-position: 50% 0.5em;
  height: 5rem;
}

@media (min-width: 768px) {
  .global-auth-wrapper:before {
    background-position: 0.5rem 0.5rem;
  }
}

.global-auth-wrapper .amplify-button {
  border-radius: 2em;
}

.global-auth-wrapper.sign-in button.amplify-button {
  display: none;
}

.global-auth-wrapper .amplify-passwordfield {
  display: none;
}

.global-auth-wrapper .amplify-field__show-password {
  border-radius: 0;
}

select:required:invalid {
  color: gray;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.amplify-tabs {
  display: none;
}

.amplify-button[data-variation='primary'] {
  background: var(--coral-med);
  border: none;
  box-shadow: none;
  color: var(--white-pure);
}

.amplify-button[data-variation='primary']:disabled {
  background: var(--grey-5);
  color: var(--grey-3);
  box-shadow: inset 0 5px 4px 0 rgba(0, 0, 0, 0.05);
}

[data-amplify-footer] {
  display: none;
}

.amplify-button--link:active {
  background-color: transparent;
}

.amplify-button--link:focus {
  background-color: transparent;
  color: var(--coral-dark);
}

.amplify-button:focus {
  box-shadow: none;
}

.amplify-button--link:hover {
  background-color: transparent;
}

.amplify-tabs-item:focus {
  color: var(--coral-dark);
}

.amplify-button--link:visited {
  background-color: transparent;
}

/* SCROLLBAR STYLES */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #959a9d;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cacdce;
}

#react-phone-number-input {
  border-width: 0px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#react-phone-number-input::placeholder {
  color: #cacdce;
}

.price-tag {
  background-color: #ffa38d;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
}

.cluster-tag {
  background-color: var(--teal-dark);
  border-radius: 5em;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
}

.activeMarker {
  z-index: 1000;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 17px;
  position: relative;
  transition: padding 0.3s ease-in-out;
  background-color: #ffffff;
  color: #ffa38d;
}

.price-tag::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffa38d;
}

.activeMarker::after {
  border-top: 8px solid #ffffff;
}

/* IMAGE SWIPER STYLES */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 75%;
  width: 100%;
}

.mySwiper {
  height: 25%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.5;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  z-index: 10 !important;
}

.background-image {
  width: 100vw;
  height: 100%;
  background-color: #fadfcf;
  background-image: url(https://assets-global.website-files.com/61e19be21795b3fa062d021f/65f31bd9da71b7709d97e561_404%20Background.svg);
  background-size: cover;
  background-attachment: scroll;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rangeSlider {
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgb(255, 170, 148);
  cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: rgb(255, 170, 148);
  border-radius: 50%;
  cursor: pointer;
}
